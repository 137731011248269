import Footer from "../Footer";
import GlobalNav from "../GlobalNav";
import useAnimation from "../hooks/useAnimation";


export default function Layout(props) {
    useAnimation();
    return (<>
        <div  className={`top-nav ${props.isTransparent ? "nav-transparent" : "nav-dark-blue"}`}>
            <div className="ready">
                <GlobalNav user={props.user} mobileMenuIsOpen={props.mobileMenuIsOpen} setMobileMenu={props.setMobileMenu} />
            </div>
            {!props.isTransparent && <div className="divider-cont">
                <div className="nav-divider" />
            </div>}
        </div>

        <div  id="main-wrapper" className={(props.mobileMenuIsOpen ? " menu-open " : "") + " page " + " main-wrapper "}>
            <main className="main">
                <div className="inner">
                                
                    {props.children}

                </div>
            </main>
            <div className="footer-bar">
                <Footer handleToast={props.handleToast} />
            </div>
        </div>
        <style jsx global>{`
            body{
                overflow:${props.mobileMenuIsOpen ? "hidden" : "auto"};
                overflow-x:hidden;
            }

            .nav-divider{
                border-top: 1px solid var(--code-border);
                border-left:300px solid transparent;
                border-right:300px solid transparent;
                background: var(--background);
              }
            .divider-cont{
                overflow:hidden;
                width:100%;

            }
            .top-nav{
                position: fixed;
                width:100%;
                top:0;
                z-index:999;
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 150ms;
                box-shadow: 0 20px 25px -5px rgb(0 0 0/.1),0 8px 10px -6px rgb(0 0 0/.1);
                border-radius: 0 0 var(--border-radius) var(--border-radius);
            }
            .nav-transparent{ 
                background:transparent;
                box-shadow: 0;
            }

            .top-nav:hover {
                background: var(--background);
            }

            .main-wrapper {
                overflow-x:hidden;
            }
            .inner {
                max-width: 100vw;
                width: 100%;
            }
            
            .date {
                font-size: var(--font-size-3);
                margin-top: -1rem;
                color: var(--pink);
            }

          .page {
            justify-content:center;
            min-height: calc(100vh - var(--top-nav-height));
          }
          .page main {
            display:flex;
          }

          .page.menu-open {
            overflow: hidden;
            height: 100%;
          }

          .dark .page {
            border-bottom-color: var(--black-light);
          }

          .main{
           width: 100%;
          }

          article {
            padding: 2rem 1.5rem 3rem;
          }

          .footer-bar {
            flex: 1;
            padding: 0;
          }

          @media (max-width: 768px) {
            .mobile-hide {
                display:none;
            }
            }

        `}
        </style>
    </>
    );
}