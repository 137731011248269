export const GradientDivider = ({height = "500px", animateIn = false}) => {
    return <div className="separator"><style jsx>
        {`
            .separator {
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: ${height};
                z-index: 0;
                ${(animateIn && `animation: animate-in 3s forwards;
                animation-delay: 0s;
                opacity: 0;
                `)}
              }

              :global(.dark .separator) {
                background: linear-gradient(0deg, var(--covalent-dark-blue) 0%, transparent 100%);
            }

              @keyframes animate-in {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }

        `}
    </style></div>;
};

export const GradientDividerTop = ({height = "500px", animateIn = false}) => {
    return <div className="separator-top"><style jsx>
        {`
            .separator-top {
                position: absolute;
                top: -1px;
                left: 0;
                width: 100%;
                height: ${height};
                z-index: 0;
                ${(animateIn && `animation: animate-in 20s forwards;
                animation-delay: 0s;
                opacity: 0;
                `)}
              }

              :global(.dark .separator-top) {
                background: linear-gradient(0deg, transparent 0%, var(--covalent-dark-blue) 100%);
            }

              @keyframes animate-in {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }

        `}
    </style></div>;
};

export const GradientDividerLeft = () => {
    return <div className="separator-left"><style jsx>
        {`
            .separator-left {
                position: absolute;
                top: 0;
                left: 0;
                width: 1000px;
                height: 100%;
                z-index: 0;
              }

            :global(.dark .separator-left) {
                background: linear-gradient(90deg, var(--covalent-dark-blue) 0%, transparent 100%);
            }
        `}
    </style></div>;
};

export const GradientDividerFull = ({animateIn = false}) => {
    return <div className="separator-full"><style jsx>
        {`
            .separator-full {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                ${(animateIn && `animation: animate-in-full 15s forwards;
                animation-delay: 1s;
                opacity: 0;
                `)}
              }

              :global(.dark .separator-full) {
                background: var(--covalent-dark-blue);
            }

              @keyframes animate-in-full {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 0.65;
                }
            }

        `}
    </style></div>;
};