import React from "react";

export const HugeCTAWrapper = ({ children, hasVideo = false, dark = false, background = "" }) => {
    return (
        <><div className={"hugecta " + (dark && "dark")}>
            <div className="huge_cta_wrapper">
                <div className="hugecta__content">
                    <div className={background !== "" ? "background-image" : ""}/>
                    {children}
                </div>
            </div>
        </div>
        <style jsx>{`
            .hugecta {
                display: flex;
                height: max-content;
                min-height: 30vh;
                width: 100vw;
                background-color: var(--white);
                background-image: var(--gradient);
                position: relative;
                padding: 0;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
            }
            .background-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                max-width: 100%;
                background-image: url(${background});
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                z-index: 0;
            }
            :global(.dark .hugecta) {
                background-color: ${hasVideo ? "#000426c9" : "transparent"};
                backdrop-filter: blur(10px);
                background: rgba(0, 4, 38, 0.55);
                color: #fff;
                z-index: 0;
            }
            .hugecta__content {
                height: 100%;
                min-height: max-content;
                width: 100%;
            }
            .huge_cta_wrapper {
                max-width: var(--landing-page-max-width);
                width: 100%;
                padding: var(--landing-page-padding);
                place-self: center;
            }
            :global(.hugecta__content:not(.dark .hugecta__content)) {
                background: #ffffff8c;
                border-radius: calc(var(--border-radius) * 4);
                border: 3px solid white;
                box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
                padding: 6rem;
                max-width: var(--landing-page-max-width);
            }

            @media (max-width: 768px) {
                .hugecta {
                    flex-direction: column;
                    min-height:80vh;
                    justify-content:center;
                }
                .hugecta__content {
                    width: 100%;
                    height: auto;
                    padding: var(--landing-page-padding);
                }
                :global(.hugecta__content:not(.dark .hugecta__content)) {
                    padding: 2rem;
                }
            }
        `}</style></>

    );
};

export const HugeCTATitle = ({ children }) => {
    return (
        <h2 className="hugecta__title gradient-text animate-in">{children}
            <style jsx>{`
            .hugecta__title {
                font-size: var(--font-size-8);
                margin-left: -5px;
                max-width: 50rem;
                line-height: 1.2;
                z-index: 2;
                position: relative;
                font-weight: var(--font-weight-bold);
            }
            .gradient-text {
                background: linear-gradient(90deg, var(--pink), var(--teal));
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            @media (max-width: 768px) {
                .hugecta__title {
                    font-size: var(--font-size-7);
                    max-width: 100%;
                }
            }
        `}</style></h2>
    );
};

export const HugeCTASubtitle = ({ children }) => {
    return (
        <p className="hugecta__subtitle animate-in">{children}
            <style jsx>{`
            .hugecta__subtitle {
                font-size: var(--font-size-4);
                line-height: var(--line-height-4);
                margin-bottom: 0rem;
                margin-top: 1.5rem;
                max-width: 30rem;
                color: var(--text);
            }
            @media (max-width: 768px) {
                .hugecta__subtitle {
                    max-width: 100%;
                }
            }
        `}</style></p>
    );
};

export const HugeCTABadge = ({ children }) => {
    return (
        <div className="hugecta__badge">{children}
            <style jsx>{`
            .hugecta__badge {
                font-size: var(--font-size-5);
                margin-bottom: 0.6rem;

            }
        `}</style></div>
    );
};

export const HugeCTACtas = ({ children }) => {
    return (
        <div className="hugecta__ctas animate-in">
            {children}
            <style jsx>{`
            .hugecta__ctas {
                gap: 1rem;
                display: flex;
                margin-top: 1rem;
            }
            @media (max-width: 768px) {
                .hugecta__ctas {
                    flex-direction: column;
                }
            }
        `}</style></div>
    );
};

export const HugeCTACTA = ({ children }) => {
    return (
        <div className="hugecta__cta">{children}</div>
    );
};
